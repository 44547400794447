<style scoped></style>
<template>
  <div class="pb20">
    <a-card class="w-100">
      <panel-group :totalData="indexData" @handleSetLineChartData="handleSetLineChartData" />
    </a-card>
    <a-row :gutter="30">
      <a-col class="mt20" :xs="12" :sm="12" :lg="12">
        <a-card class="w-100">
          <div class="w-100" id="bar1" style="height:500px"></div>
        </a-card>
      </a-col>
      <a-col class="mt20" :xs="12" :sm="12" :lg="12">
        <a-card class="w-100">
          <div class="w-100" id="line" style="height:500px"></div>
        </a-card>
      </a-col>
      <a-col class="mt20" :xs="12" :sm="12" :lg="12">
        <a-card class="w-100">
          <div class="w-100" id="bar2" style="height:500px"></div>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import * as echarts from "echarts"
import PanelGroup from "@/components/PanelGroup/PanelGroup"
import { data_general } from "@/api/data"
export default {
  components: {
    PanelGroup,
  },
  data() {
    return {
      indexData: {
        goods: 0,
        user: 0,
        order: 0,
        available_order: 0,
        total_amount: 0,
        payed_amount: 0,
        coin: 0,
        goods_amount: 0,
        agent_orders: [],
      },
    }
  },
  mounted() {
    // data_general()
    //   .then((res) => {
    //     this.indexData.goods = res.goods
    //     this.indexData.user = res.user
    //     this.indexData.order = res.order
    //     this.indexData.available_order = res.available_order
    //     this.indexData.goods_amount = res.goods_amount / 100
    //     this.indexData.payed_amount = res.payed_amount / 100
    //     this.indexData.coin = res.coin / 100
    //     this.indexData.coin = res.coin / 100
    //     this.indexData.total_amount =
    //       (parseFloat(res.payed_amount) + parseFloat(res.coin)) / 100
    //     this.barInit(res.recent_order, "bar1")
    //     this.barInit(res.recent_user, "line", "line")
    //     let recent_amount = {}
    //     for (const key in res.recent_amount) {
    //       recent_amount[key] = res.recent_amount[key] / 100
    //     }
    //     this.barInit(recent_amount, "bar2")
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  },
  methods: {
    barInit(data, id, type = "bar") {
      let xData = []
      let yData = []
      for (const item in data) {
        xData.push(item)
        yData.push(data[item])
      }
      let series = {
        name: id == "pie" ? "积分" : id == "bar2" ? "金额" : "数量",
        type: type,
        label: { show: true, position: "top" },
        data: yData,
      }
      if (id != "pie") {
        var colors = {
          bar1: "#f4516c",
          bar2: "#34bfa3",
          line: "#36a3f7",
        }

        series.itemStyle = {
          color: colors[id],
        }
      }
      var mycahrt = echarts.init(document.getElementById(id)) //显示 id main  dark 主题
      var option = {
        backgroundColor: "#fff", //背景
        title: {
          text:
            id == "line"
              ? "近期用户增长趋势"
              : id == "pie"
                ? "用户消费积分分布统计"
                : id == "bar1"
                  ? "近期订单数量趋势"
                  : "近期交易金额趋势", //      //标题,可选
        },
        tooltip: {},
        // legend: {                     //可选
        //     data:['数量'],           //柱坐标
        //       top:'bottom'         //显示位置
        //     },
        xAxis: {
          splitLine: { show: false }, //是否显示网格线，默认不显示，可选
          data: xData,
        },
        yAxis: { splitLine: { show: false } }, //默认显示，可选
        series: [series],
      }
      mycahrt.setOption(option) //生成图表
    },
    handleSetLineChartData(type) {
      console.log(type)
      // this.lineChartData = lineChartData[type]
    },
  },
}
</script>
